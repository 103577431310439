import { normalizeSuiObjectId, fromB64 } from "@mysten/sui/utils";
import { Transaction } from "@mysten/sui/transactions";

export const substituteArg = (txb, arg, type, outputs, moveVecOffest) => {
  if (arg.txIndex !== undefined) {
    return outputs[arg.txIndex + moveVecOffest][arg.index];
  } else {
    switch (type) {
      case "object":
        return txb.object(normalizeSuiObjectId(arg));
      default:
        return txb.pure(arg);
    }
  }
};

export const makeTransactionBlock = (transactions, userAddress) => {
  const txb = new Transaction();
  const outputs = [];
  const moveVecOffest = 0;
  transactions.forEach((tx) => {
    let output = false;
    switch (tx.type) {
      case "publish":
        output = txb.publish({
          modules: tx.modules.map((m) => Array.from(fromB64(m))),
          dependencies: tx.dependencies.map((addr) => normalizeSuiObjectId(addr)),
        });
        outputs.push(output);
        output = txb.transferObjects(
          [outputs[outputs.length - 1]],
          txb.pure(userAddress)
        );
        outputs.push(output);
        break;
      case "transferObjects":
        outputs.push(makeTransferObjects(tx, txb, outputs, moveVecOffest));
        break;
      case "splitCoins":
        makeSplitCoins(tx, txb, outputs, moveVecOffest);
        break;
      case "mergeCoins":
        makeMergeCoins(tx, txb, outputs, moveVecOffest);
        break;
      case "moveCall":
      default:
        output = makeMoveCall(tx, txb, outputs, moveVecOffest);
        outputs.push(output);
        break;
    }
  });
  return txb;
};

const parseArgTypes = (tx, txb, outputs, moveVecOffest) => {
  let args = [];
  tx.arguments.forEach((arg, index) => {
    let newArg = false;
    if (arg?.txIndex !== undefined) {
      newArg = substituteArg(txb, arg, tx.types?.[index], outputs, moveVecOffest);
    } else {
      let types = tx.types?.[index]?.replaceAll(">", "").split("<");
      if (types.length > 1) {
        if (types[1].includes("vec")) {
          if (types[2] == "object") {
            const outerArray = [];
            arg.forEach((subArg) => {
              let [arrayItem] = txb.makeMoveVec({
                type: types[2],
                objects: subArg.map((a) => txb.object(txb, a)),
              });
              outerArray.push(arrayItem);
              moveVecOffest++;
            });
            newArg = txb.makeMoveVec({
              type: types[1],
              objects: outerArray,
            });
            moveVecOffest++;
          } else {
            newArg = txb.pure(arg);
          }
        } else if (types[1] == "object") {
          newArg = txb.makeMoveVec({
            type: types[1],
            objects: arg.map((a) => txb.object(txb, a)),
          });
          moveVecOffest++;
        } else {
          newArg = txb.pure(arg);
        }
      } else {
        newArg = parseArg(txb, arg, types[0]);
      }
    }
    args.push(newArg);
  });
  return args;
};

const parseArg = (txb, arg, type) => {
  if (arg.kind === "GasCoin") {
    return txb.gas;
  }
  switch (type) {
    case "object":
      return txb.object(normalizeSuiObjectId(arg));
    case "bool":
      return txb.pure(arg, "bool");
    default:
      return txb.pure(arg);
  }
};

const makeMoveCall = (tx, txb, outputs, moveVecOffest) => {
  let args = parseArgTypes(tx, txb, outputs, moveVecOffest);
  if (!tx.target) {
    tx.target = `${tx.packageObjectId}::${tx.module}::${tx.function}`;
  }
  return txb.moveCall({
    target: tx.target,
    arguments: args,
    typeArguments: tx.typeArguments,
  });
};

const makeSplitCoins = (tx, txb, outputs) => {
  const coins = txb.splitCoins(
    txb.gas,
    tx.amounts.map((amount) => txb.pure(amount))
  );
  outputs.push(coins);
};

const makeMergeCoins = (tx, txb, outputs, moveVecOffest) => {
  let pureCoins = tx.coins.map((a) =>
    substituteArg(txb, a, "object", outputs, moveVecOffest)
  );
  txb.mergeCoins(pureCoins.shift(), pureCoins);
  outputs.push([]);
};

const makeTransferObjects = (tx, txb, outputs, moveVecOffest) => {
  let args = tx.object ? [tx.object] : tx.objects;
  args = args.map((arg, index) =>
    substituteArg(txb, arg, tx.types?.[index], outputs, moveVecOffest)
  );
  return txb.transferObjects(args, txb.pure(tx.to));
};
