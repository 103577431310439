import { Transaction } from "@mysten/sui/transactions";
import {
  normalizeSuiAddress,
  isValidSuiObjectId,
  SUI_FRAMEWORK_ADDRESS,
  SUI_CLOCK_OBJECT_ID,
} from "@mysten/sui/utils";
import { SuiClient } from "@mysten/sui/client";

import { ethos } from "ethos-connect";
import { IS_PROD } from "utils/environments";
import { suiToMyst, formatSuiItem } from "utils/formats";
import { filter } from "utils/performance";
import { makeTransactionBlock } from "./txnbuilder";

export const SuiAddress = normalizeSuiAddress(SUI_FRAMEWORK_ADDRESS);
export const SUI = `${SuiAddress}::sui::SUI`;

let getNetwork = () => {
  let pools = [
    "https://api.shinami.com:443/node/v1/sui_testnet_d00e9e3e1e8f3632d0cc5041229b4b91",
    "https://api.shinami.com:443/node/v1/sui_testnet_d00e9e3e1e8f3632d0cc5041229b4b91",
  ];
  let weights = [33, 33];

  if (!IS_PROD) {
    pools.pop();
    weights.pop();
  }

  for (let i = 1; i < weights.length; i++) {
    weights[i] += weights[i - 1];
  }

  var random = Math.random() * weights[weights.length - 1];

  for (let i = 0; i < weights.length; i++) {
    if (weights[i] > random) {
      return pools[i];
    }
  }
};

export const network = getNetwork();

console.log(network);

let provider = new SuiClient({
  url: network,
});
let signer = false;

let settings = {
  nft_bytes: "",
  allowlist: "",
  package_id: "",
  module_name: "",
  market: {},
  admin_address: "",
  tags: [],
  collateral_fee: 0,
};

let defaultDetails = {
  packageObjectId: settings.package_id,
  module: "keepsake_marketplace", // settings.module_name,
  typeArguments: [],
};

let userAddress = false;
let gasObjects = [];

export const updateGasObjects = (newObjects) => {
  gasObjects = newObjects;
};

export const updateSettings = async (newSettings, marketInfo) => {
  settings = newSettings;
  if (marketInfo) {
    settings.market.collateral_fee = marketInfo.data.collateral_fee;
    settings.market.fee_bps = marketInfo.data.fee_bps;
    settings.market.owner = marketInfo.data.owner;
    settings.package_id = marketInfo.type.split("::")[0];
    defaultDetails.packageObjectId = settings.package_id;
  }
  // defaultDetails.module = settings.module_name;
};

export const currentSettings = () => settings;

export const formatTransaction = (tx) => {
  const response = {};
  if (tx.EffectsCert) {
    response.effects = tx.EffectsCert.effects.effects;
    response.certificate = tx.EffectsCert.certificate;
    response.status = tx.EffectsCert.effects.effects.status.status;
  } else {
    response.effects = tx.effects;
    response.certificate = tx.certificate;
    response.status = tx.effects.status.status;
  }
  return response;
};

export const getTxObjects = async (txResults) => {
  if (txResults.effects.created) {
    const created = txResults.effects.created.map((item) => item.reference.objectId);
    const createdInfo = await getObjectsInfo(created, {
      showContent: true,
      showType: true,
    });
    let packageObjectId = false;
    let createdObjects = [];

    createdInfo.forEach((item) => {
      if (item.data?.dataType === "package") {
        packageObjectId = item.id;
      } else {
        createdObjects.push(item);
      }
    });
    return [packageObjectId, createdObjects];
  }
  return [null, null];
};

export const collectionType = (collection) =>
  `${collection.object_id}::${collection.module_name}::${collection.nft_name}`;

const promiseTransact = async (txArray, payment) => {
  let transactions = Array.isArray(txArray) ? txArray : [txArray];

  // let allArguments = [];
  transactions.forEach((tx) => {
    // if (tx.arguments) {
    //   allArguments = tx.arguments.filter((a) => isValidSuiObjectId(a));
    // }
    if (!tx.target && !tx.packageObjectId) {
      tx.packageObjectId = defaultDetails.packageObjectId;
      if (!tx.module) {
        tx.module = defaultDetails.module;
      }
    }
  });
  const txb = makeTransactionBlock(transactions, userAddress);

  console.log(JSON.stringify(txb.blockData));
  txb.setSender(userAddress);

  // const [estimatePayment, gasBalance] = await getLargestCoin(gasObjects, allArguments);
  // let gasBudget = Math.min(gasBalance, 1000000);
  if (payment) {
    txb.setGasPayment([txb.object(payment)]);
  }

  try {
    // ensure transaction actually works before trying it with a wallet
    // let res = await txb.build({ provider });
    // console.log(res);
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }

  return new Promise((resolve, reject) => {
    ethos
      .signTransactionBlock({
        signer,
        transactionInput: {
          transactionBlock: txb,
          acccount: userAddress,
        },
      })
      .then((signedTx) =>
        provider
          .executeTransactionBlock({
            transactionBlock: signedTx.transactionBlockBytes,
            signature: signedTx.signature,
            options: {
              showEffects: true,
            },
            requestType: "WaitForLocalExecution",
          })
          .then((tx) => {
            if (tx?.effects?.status?.status === "success") {
              resolve(formatTransaction(tx));
            } else if (tx?.effects?.status?.error) {
              reject(tx?.effects?.status?.error);
            }
            reject(tx);
          })
      )
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const signTransactionBlock = (data) => {
  return new Promise((resolve, reject) => {
    console.log(data);
    ethos
      .signTransactionBlock({
        signer,
        transactionInput: {
          transactionBlock: Transaction.from(Buffer.from(data)),
          acccount: userAddress,
        },
      })
      .then((signedTx) => resolve(signedTx))
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const showWallet = () => ethos.showWallet();

export const loginSignature = async (signer) => {
  var enc = new TextEncoder();
  let data = `keepsake.gg::login::${Date.now()}`;
  const message = enc.encode(data);
  let response = await signer.signMessage({ message });
  return { data, signedMessageResponse: response.signature || response };
};

export const asValidObjectID = (value) => {
  const prepend = "0x";
  let retval = value.replace(/[^A-Za-z0-9]/g, "");
  if (retval.length < 2) {
    return retval;
  }
  if (retval.startsWith(prepend)) {
    retval = retval.substring(2);
  }
  return normalizeSuiAddress(retval.replace(/[^A-Fa-f0-9]/g, ""));
};

export const padType = (type) => {
  const types = type.split("::");
  return asValidObjectID(types[0]) + "::" + types[1] + "::" + types[2];
};

export const isValidObjectId = (value) => isValidSuiObjectId(value);

export const setProvider = (newProvider, newSigner, newAddress) => {
  if (newProvider) {
    // provider = newProvider;
    // serializer = new LocalTxnDataSerializer(newProvider);
  }
  signer = newSigner;
  userAddress = newAddress;
};

export const disconnect = () => {
  ethos.disconnect(true);
  userAddress = false;
};

export const getUserObjects = ({
  cursor = null,
  limit = 50,
  address,
  filter,
  // consider removing showOwner
  options = { showType: true, showContent: true, showOwner: true, showDisplay: true },
}) =>
  provider
    .getOwnedObjects({
      owner: address || userAddress,
      cursor,
      limit,
      filter,
      options,
    })
    .then((res) => {
      return { ...res, ...{ data: res.data.map((a) => formatSuiItem(a)) } };
    });

export const getObjectsOwnedByObject = (objectId, cursor, limit) =>
  provider.getDynamicFields({ parentId: objectId, cursor, limit });

export const devInspectTransaction = (sender, transactionBlock) =>
  provider.devInspectTransactionBlock({
    transactionBlock,
    sender: sender || userAddress,
  });

export const dryRunTransactionBlock = (txBytes) =>
  provider.dryRunTransactionBlock({ transactionBlock: txBytes });

export const getObjectInfo = (
  objectId,
  options = { showType: true, showContent: true, showOwner: true }
) =>
  provider
    .getObject({
      id: objectId,
      options: options,
    })
    .then((a) => formatSuiItem(a));

export const getObjectsInfo = async (
  objectIds,
  options = { showContent: true, showType: true }
) => {
  let fullContents = [];
  if (objectIds.length > 50) {
    let promises = [];
    let i = 0;
    while (i < objectIds.length) {
      promises.push(
        provider.multiGetObjects({ ids: objectIds.slice(i, i + 50), options })
      );
      i += 50;
    }
    let data = await Promise.all(promises);
    i = 0;
    data.forEach((element) => {
      fullContents = fullContents.concat(element);
    });
    return fullContents.map((a) => formatSuiItem(a));
  } else {
    fullContents = await provider
      .multiGetObjects({ ids: objectIds, options })
      .then((info) => info.map((a) => formatSuiItem(a)));
    return fullContents;
  }
};

export const getTransaction = (txhash) =>
  provider
    .getTransactionBlock({
      digest: txhash,
      options: {
        showEvents: true,
        showObjectChanges: true,
        showEffects: true,
      },
    })
    .then((tx) => formatTransaction(tx));

export const getEvents = (query, cursor = null, limit = "50", order = "Ascending") =>
  provider.queryEvents({ query });
/*
    | "All"
    | { "Transaction": TransactionDigest }
    | { "MoveModule": { package: ObjectId, module: string } }
    | { "MoveEvent": string }
    | { "EventType": EventType }
    | { "Sender": SuiAddress }
    | { "Recipient": ObjectOwner }
    | { "Object": ObjectId }
    | { "TimeRange": { "start_time": number, "end_time": number } };
*/

export const getUserCoins = async (type = SUI) => {
  const userObjects = await getUserObjects({
    filter: {
      StructType: `${SuiAddress}::coin::Coin<${type}>`,
    },
  });
  let largest = 0;
  const items = [];
  userObjects.data.forEach((item) => {
    items.push(item.id);
  });
  let suiObjects = (await getObjectsInfo(items)).filter((a) => a);
  let total = 0;
  suiObjects = suiObjects.map(({ id, data: { balance } }) => {
    const intBalance = parseInt(balance);
    total += intBalance;
    if (intBalance > largest) {
      largest = intBalance;
    }
    return { id, data: { balance: intBalance } };
  });
  return { total, largest, suiObjects };
};

const generateSkipIds = (args) => {
  const skipObjectIds = [];
  args.forEach((a) => {
    if (typeof a === "string" && isValidSuiObjectId(a)) {
      skipObjectIds.push(a);
    } else if (Array.isArray(a)) {
      a.forEach((b) => {
        if (typeof b === "string" && isValidSuiObjectId(b)) {
          skipObjectIds.push(b);
        }
      });
    }
  });
  return skipObjectIds;
};

export const getPaymentCoin = (amount, suiObjects, args = []) => {
  let diff = Number.MAX_SAFE_INTEGER;
  let coinObject = false;
  const skipList = generateSkipIds(args);
  suiObjects.forEach(({ id, data }) => {
    if (skipList.includes(id)) {
      return;
    }
    const amnt = parseInt(data.balance);
    const thisDiff = amnt - amount;
    if (thisDiff >= 0 && thisDiff < diff) {
      coinObject = id;
      diff = thisDiff;
    }
  });
  return coinObject;
};

export const getLargestCoin = async (suiObjects, args = []) => {
  let max = 0;
  let coinObject = false;
  const skipObjectIds = generateSkipIds(args);

  suiObjects.forEach((coin) => {
    if (skipObjectIds.includes(coin.id)) {
      return;
    }
    const amnt = parseInt(coin.data.balance);
    if (max < amnt) {
      max = amnt;
      coinObject = coin;
    }
  });
  return [coinObject, max];
};

export const getObjectByType = (types, from, notTypes) => {
  const item = from.find((item) => filter(item.type, types, notTypes));
  if (item) {
    return item;
  }
  return false;
};

export const getObjectsByType = (types, from = false, notTypes) => {
  const items = from.filter((item) => filter(item.type, types, notTypes));
  if (items) {
    return items;
  }
  return false;
};

export const getObjectsByObjectType = (types, from, notTypes) => {
  return from.filter((item) => filter(item.objectType, types, notTypes));
};

export const mergeCoins = async (coins) =>
  promiseTransact({
    target: `${SuiAddress}::coin::join`,
    typeArguments: [SUI],
    types: ["object", "object"],
    arguments: coins,
  });

export const makeNftListing = async (nft, collection, price, userKiosk) => {
  let realPrice = suiToMyst(price);
  const marketShare = Math.floor(
    Math.ceil(realPrice / 10_000) * settings.market.fee_bps
  ).toString();
  let txns = [];
  if (nft.kiosk) {
    txns.push({
      target: `${settings.packages.ob_liquidity_layer_v1}::orderbook::create_ask_with_commission`,
      typeArguments: [nft.type, SUI],
      arguments: [
        collection.orderbook,
        nft.kiosk,
        realPrice.toString(),
        nft.id,
        settings.market.owner,
        marketShare,
      ],
      types: ["object", "object", "u64", "id", "address", "u64"],
    });
  } else if (collection.ob_enabled) {
    // first transfer it to a kiosk
    if (!userKiosk) {
      // create a kiosk, then deposit NFT
      txns.push(createKiosk(true));
      nft.kiosk = { txIndex: 0, index: 0 };
    } else {
      nft.kiosk = userKiosk;
    }

    txns.push({
      target: `${settings.packages.ob_kiosk}::ob_kiosk::deposit`,
      typeArguments: [nft.type],
      arguments: [nft.kiosk, nft.id],
      types: ["object", "object"],
    });

    txns.push({
      target: `${settings.packages.ob_liquidity_layer_v1}::orderbook::create_ask_with_commission`,
      typeArguments: [nft.type, SUI],
      arguments: [
        collection.orderbook,
        nft.kiosk,
        realPrice.toString(),
        nft.id,
        settings.market.owner,
        marketShare,
      ],
      types: ["object", "object", "u64", "id", "address", "u64"],
    });
    if (!userKiosk) {
      txns.push({
        target: `${SuiAddress}::transfer::public_share_object`,
        typeArguments: [`${SuiAddress}::kiosk::Kiosk`],
        arguments: [nft.kiosk],
        types: ["object"],
      });
    }
  } else {
    txns.push({
      function: "list",
      typeArguments: [nft.type],
      arguments: [
        settings.market.marketplace,
        settings.market.kiosk,
        collection.transfer_policy,
        nft.id,
        realPrice.toString(),
      ],
      types: ["object", "object", "object", "object", "number"],
    });
  }
  return promiseTransact(txns);
};

export const buyNftListing = async (listing, price, buyer_kiosk) => {
  // const { suiObjects } = await getUserCoins(listing.sale_token);
  // const [coin] = await getLargestCoin(suiObjects);

  let royaltyCost = 0;

  if (listing.seller_kiosk) {
    listing.nft_collection.ob_royalties.forEach((royalty) => {
      royaltyCost += Math.ceil(Math.ceil(royalty.amount * price) / 10000);
    });
  } else {
    listing.nft_collection.royalties.forEach((royalty) => {
      royaltyCost += Math.ceil(Math.ceil(royalty.amount * price) / 10000);
    });
  }

  let external_fee = Math.ceil(Math.ceil(50 * price) / 10000);
  if (listing.external) {
    royaltyCost += external_fee;
  }

  let txns = [
    {
      type: "splitCoins",
      // coin: coin.id,
      amounts: [price, Math.ceil(royaltyCost * 1.001)],
    },
    {
      target: `${SuiAddress}::coin::into_balance`,
      typeArguments: [SUI],
      arguments: [{ txIndex: 0, index: 1 }],
      types: ["object"],
    },
  ];
  if (listing.seller_kiosk) {
    txns.push({
      target: `${settings.packages.ob_liquidity_layer_v1}::orderbook::buy_nft`,
      typeArguments: [listing.object_type, SUI],
      arguments: [
        listing.orderbook,
        listing.seller_kiosk,
        buyer_kiosk,
        listing.nft_object_id,
        price.toString(),
        { txIndex: 0, index: 0 },
      ],
      types: ["object", "object", "object", "id", "u64", "object"],
    });
  } else {
    txns.push({
      function: "buy",
      typeArguments: [listing.object_type],
      arguments: [
        settings.market.marketplace,
        settings.market.kiosk,
        listing.nft_object_id,
        { txIndex: 0, index: 0 },
      ],
      types: ["object", "object", "address", "object"],
    });
  }

  if (listing.seller_kiosk) {
    if (listing.nft_collection.ob_royalties) {
      listing.nft_collection.ob_royalties.forEach((royalty) => {
        txns.push({
          target: `${royalty.package_id}::${
            royalty.type.split("::")[0]
          }::confirm_transfer_with_balance`,
          typeArguments: royalty.specifyType
            ? [listing.object_type, SUI]
            : [listing.object_type],
          arguments: [royalty.id, { txIndex: 2, index: 0 }, { txIndex: 1, index: 0 }],
          types: ["object", "object", "object"],
        });
      });
    }
  } else if (listing.nft_collection.royalties) {
    listing.nft_collection.royalties.forEach((royalty) => {
      txns.push({
        target: `${royalty.package_id}::${
          royalty.type.split("::")[0]
        }::confirm_transfer_with_balance`,
        typeArguments: royalty.specifyType
          ? [listing.object_type, SUI]
          : [listing.object_type],
        arguments: [royalty.id, { txIndex: 2, index: 0 }, { txIndex: 1, index: 0 }],
        types: ["object", "object", "object"],
      });
    });
  }

  if (listing.seller_kiosk) {
    txns.push({
      target: `${settings.packages.nft_protocol}::transfer_allowlist::confirm_transfer`,
      typeArguments: [listing.object_type],
      arguments: [listing.nft_collection.ob_allowlist, { txIndex: 2, index: 0 }],
      types: ["object", "object"],
    });
    txns.push({
      target: `${settings.packages.ob_request}::transfer_request::confirm`,
      typeArguments: [listing.object_type, SUI],
      arguments: [{ txIndex: 2, index: 0 }, listing.nft_collection.ob_transfer_policy],
      types: ["object", "object"],
    });
  } else {
    txns.push({
      target: `${settings.packages.keepsake}::transfer_policy::confirm_request`,
      typeArguments: [listing.object_type],
      arguments: [listing.nft_collection.transfer_policy, { txIndex: 2, index: 0 }],
      types: ["object", "object"],
    });
  }
  if (listing.external) {
    txns.push({
      target: `${SuiAddress}::coin::take`,
      typeArguments: [SUI],
      arguments: [{ txIndex: 1, index: 0 }, external_fee.toString()],
      types: ["object", "u64"],
    });
    txns.push({
      type: "transferObjects",
      object: { txIndex: txns.length - 1, index: 0 },
      to: settings.market.owner,
    });
  }
  txns.push({
    target: `${SuiAddress}::coin::from_balance`,
    typeArguments: [SUI],
    arguments: [{ txIndex: 1, index: 0 }],
    types: ["object"],
  });
  txns.push({
    type: "transferObjects",
    object: { txIndex: txns.length - 1, index: 0 },
    to: userAddress,
  });
  if (listing.seller_kiosk) {
    txns.push({
      type: "transferObjects",
      object: { txIndex: 0, index: 0 },
      to: userAddress,
    });
  }

  return promiseTransact(txns);
};

export const makeNftAuction = async (
  nft,
  collection,
  { min_bid, min_bid_increment, starts, expires },
  userKiosk
) => {
  const truePrice = suiToMyst(min_bid).toString();
  const realBidIncrement = suiToMyst(min_bid_increment).toString();
  let formattedStarts = new Date(starts).getTime().toString();
  let formattedExpires = new Date(expires).getTime().toString();
  // const { suiObjects } = await getUserCoins();
  // const collateral = getPaymentCoin(settings.market.collateral_fee, suiObjects);
  // if (!collateral) {
  //   throw new Error("Could not find a coin for insurance");
  // }

  let marketInfo = await getObjectInfo(
    collection.ob_enabled ? settings.market.ob_marketplace : settings.market.marketplace
  );

  let txns = Array.of({
    type: "splitCoins",
    // coin: collateral,
    amounts: [marketInfo.data.collateral_fee],
  });
  if (nft.kiosk) {
    txns.push({
      target: `${settings.packages.keepsake_ob}::keepsake_ob_marketplace::auction`,
      typeArguments: [nft.type],
      arguments: [
        settings.market.ob_marketplace,
        collection.ob_transfer_policy,
        nft.id,
        truePrice,
        realBidIncrement, // min_bid_increment
        formattedStarts,
        formattedExpires,
        { txIndex: 0, index: 0 },
        nft.kiosk,
      ],
      types: [
        "object",
        "object",
        "address",
        "number",
        "number",
        "number",
        "number",
        "object",
        "object",
      ],
    });
  } else if (collection.ob_enabled) {
    // first transfer it to a kiosk
    if (!userKiosk) {
      // create a kiosk, then deposit NFT
      txns.push(createKiosk(true));
      nft.kiosk = { txIndex: 0, index: 0 };
    } else {
      nft.kiosk = userKiosk;
    }

    txns.push({
      target: `${settings.packages.ob_kiosk}::ob_kiosk::deposit`,
      typeArguments: [nft.type],
      arguments: [nft.kiosk, nft.id],
      types: ["object", "object"],
    });
    txns.push({
      target: `${settings.packages.keepsake_ob}::keepsake_ob_marketplace::auction`,
      typeArguments: [nft.type],
      arguments: [
        settings.market.ob_marketplace,
        collection.ob_transfer_policy,
        nft.id,
        truePrice,
        realBidIncrement, // min_bid_increment
        formattedStarts,
        formattedExpires,
        { txIndex: 0, index: 0 },
        nft.kiosk,
      ],
      types: [
        "object",
        "object",
        "address",
        "number",
        "number",
        "number",
        "number",
        "object",
        "object",
      ],
    });
  } else {
    txns.push({
      function: "auction",
      typeArguments: [nft.type],
      arguments: [
        settings.market.marketplace,
        settings.market.kiosk,
        collection.transfer_policy,
        nft.id,
        truePrice,
        realBidIncrement, // min_bid_increment
        formattedStarts,
        formattedExpires,
        { txIndex: 0, index: 0 },
      ],
      types: [
        "object",
        "object",
        "object",
        "object",
        "number",
        "number",
        "number",
        "number",
        "object",
      ],
    });
  }

  return promiseTransact(txns);
};

export const bidNFTAuction = async (auction, price) => {
  const truePrice = suiToMyst(price);
  // const { suiObjects } = await getUserCoins(auction.sale_token);
  // const coin = getPaymentCoin(truePrice, suiObjects);
  let txns = Array.of({
    type: "splitCoins",
    amounts: [truePrice],
  });
  if (auction.seller_kiosk) {
    txns.push({
      target: `${settings.packages.keepsake_ob}::keepsake_ob_marketplace::bid`,
      typeArguments: [auction.object_type],
      arguments: [
        settings.market.ob_marketplace,
        auction.nft_object_id,
        { txIndex: 0, index: 0 },
        truePrice.toString(),
        SUI_CLOCK_OBJECT_ID,
      ],
      types: ["object", "address", "object", "number", "object"],
    });
  } else {
    txns.push({
      function: "bid",
      typeArguments: [auction.object_type],
      arguments: [
        settings.market.marketplace,
        auction.nft_object_id,
        { txIndex: 0, index: 0 },
        truePrice.toString(),
        SUI_CLOCK_OBJECT_ID,
      ],
      types: ["object", "address", "object", "number", "object"],
    });
  }

  return promiseTransact(txns);
};

export const winNFTAuction = async (listing, buyer_kiosk) => {
  // const { suiObjects } = await getUserCoins(listing.sale_token);
  // const [coin] = await getLargestCoin(suiObjects);
  let royaltyCost = 0;
  listing.nft_collection.royalties.forEach((royalty) => {
    royaltyCost += Math.ceil(Math.ceil(royalty.amount * listing.sale_price) / 10000);
  });

  let txns = [
    {
      type: "splitCoins",
      // coin: coin.id,
      amounts: [Math.ceil(royaltyCost * 1.001)],
    },
    {
      target: `${SuiAddress}::coin::into_balance`,
      typeArguments: [SUI],
      arguments: [{ txIndex: 0, index: 0 }],
      types: ["object"],
    },
  ];

  if (listing.seller_kiosk) {
    txns.push({
      target: `${settings.packages.keepsake_ob}::keepsake_ob_marketplace::complete_auction`,
      typeArguments: [listing.object_type],
      arguments: [
        settings.market.ob_marketplace,
        listing.seller_kiosk,
        buyer_kiosk,
        listing.nft_object_id,
        SUI_CLOCK_OBJECT_ID,
      ],
      types: ["object", "object", "object", "address", "object"],
    });
  } else {
    txns.push({
      function: "complete_auction",
      typeArguments: [listing.object_type],
      arguments: [
        settings.market.marketplace,
        settings.market.kiosk,
        listing.nft_object_id,
        SUI_CLOCK_OBJECT_ID,
      ],
      types: ["object", "object", "address", "object"],
    });
  }

  if (listing.nft_collection.royalties) {
    listing.nft_collection.royalties.forEach((royalty) => {
      txns.push({
        target: `${royalty.package_id}::${
          royalty.type.split("::")[0]
        }::confirm_transfer_with_balance`,
        typeArguments: royalty.specifyType
          ? [listing.object_type, SUI]
          : [listing.object_type],
        arguments: [royalty.id, { txIndex: 2, index: 0 }, { txIndex: 1, index: 0 }],
        types: ["object", "object", "object"],
      });
    });
  }
  if (listing.seller_kiosk) {
    txns.push({
      target: `${settings.packages.nft_protocol}::transfer_allowlist::confirm_transfer`,
      typeArguments: [listing.object_type],
      arguments: [settings.market.auction_allowlist, { txIndex: 2, index: 0 }],
      types: ["object", "object"],
    });
    txns.push({
      target: `${settings.packages.ob_request}::transfer_request::confirm`,
      typeArguments: [listing.object_type, SUI],
      arguments: [{ txIndex: 2, index: 0 }, listing.nft_collection.ob_transfer_policy],
      types: ["object", "object"],
    });
  } else {
    txns.push({
      target: `${settings.packages.keepsake}::transfer_policy::confirm_request`,
      typeArguments: [listing.object_type],
      arguments: [listing.nft_collection.transfer_policy, { txIndex: 2, index: 0 }],
      types: ["object", "object"],
    });
  }

  txns.push({
    target: `${SuiAddress}::coin::from_balance`,
    typeArguments: [SUI],
    arguments: [{ txIndex: 1, index: 0 }],
    types: ["object"],
  });

  txns.push({
    type: "transferObjects",
    object: { txIndex: txns.length - 1, index: 0 },
    to: userAddress,
  });

  return promiseTransact(txns);
};

export const unlistNFT = async (listing) => {
  if (listing.seller_kiosk) {
    if (listing.sale_type === "auction") {
      // return promiseTransact({
      //   target: `${settings.packages.keepsake_ob}::keepsake_ob_marketplace::deauction`,
      //   typeArguments: [listing.object_type],
      //   arguments: [
      //     settings.market.ob_marketplace,
      //     listing.nft_object_id,
      //     SUI_CLOCK_OBJECT_ID,
      //     listing.seller_kiosk,
      //   ],
      //   types: ["object", "address", "object", "object"],
      // });
    } else {
      return promiseTransact({
        target: `${settings.packages.ob_liquidity_layer_v1}::orderbook::cancel_ask`,
        typeArguments: [listing.object_type, SUI],
        arguments: [
          listing.orderbook,
          listing.seller_kiosk,
          listing.sale_price,
          listing.nft_object_id,
        ],
        types: ["object", "object", "u64", "id"],
      });
    }
  } else {
    if (listing.sale_type === "auction") {
      return promiseTransact({
        function: "deauction",
        typeArguments: [listing.object_type],
        arguments: [
          settings.market.marketplace,
          settings.market.kiosk,
          listing.nft_object_id,
          SUI_CLOCK_OBJECT_ID,
        ],
        types: ["object", "object", "address", "object"],
      });
    } else {
      return promiseTransact({
        function: "delist_and_take",
        typeArguments: [listing.object_type],
        arguments: [
          settings.market.marketplace,
          settings.market.kiosk,
          listing.nft_object_id,
        ],
        types: ["object", "object", "address"],
      });
    }
  }
};

// Kiosks
export const createKiosk = (forTx = false) => {
  if (forTx) {
    return {
      target: `${settings.packages.ob_kiosk}::ob_kiosk::new_for_address`,
      arguments: [userAddress],
      types: ["address"],
    };
  }

  return promiseTransact({
    target: `${settings.packages.ob_kiosk}::ob_kiosk::create_for_sender`,
    arguments: [],
    types: [],
  });
};

// Minting
export const makeMintTx = ({
  name,
  description,
  image,
  metadata,
  nft_collection,
  type,
  count,
  launchpad,
  tier,
  recipient,
}) => {
  let functionName = count > 1 ? "mint_many" : "mint";
  const attribute_keys = metadata ? Object.keys(metadata) : [];
  const attribute_values = metadata
    ? Object.keys(metadata).map((key) => metadata[key].toString())
    : [];
  let args = [
    name,
    description,
    image,
    attribute_keys,
    attribute_values,
    nft_collection.mint_object_id,
  ];
  let types = ["string", "string", "string", "vec<vec<u8>>", "vec<vec<u8>>", "object"];
  switch (type) {
    // TODO: Make launchpad 2 txns
    case "list":
    case "auction":
      if (count > 1) {
        args.push(count);
        types.push("number");
      }
      break;
    case "launchpad":
      functionName = "mint_launchpad";
      args = args.concat([launchpad.object_id, tier.object_id, count || 1]);
      types = types.concat(["object", "object", "number"]);
      break;
    default:
      functionName = "mint_to";
      args = args.concat([recipient || userAddress, count || 1]);
      types = types.concat(["address", "number"]);
      break;
  }
  return {
    packageObjectId: nft_collection.object_id,
    module: nft_collection.module_name,
    function: functionName,
    arguments: args,
    types: types,
  };
};

export const mintNFT = async (
  {
    nft_collection,
    tier,
    recipient,
    count,
    launchpad,
    price,
    min_bid_increment,
    starts,
    expires,
    name,
    description,
    image,
    metadata,
  },
  type
) => {
  let real_price = suiToMyst(price);
  let real_min_bid_incremet = suiToMyst(min_bid_increment);

  let mintTx = makeMintTx({
    name,
    description,
    image: image,
    metadata,
    nft_collection,
    type,
    count,
    tier,
    launchpad,
    recipient,
  });
  let listTx = false;
  let splitTx = false;
  // TODO: handle count, maybe limit it to... 10?
  switch (type) {
    case "list":
      listTx = {
        function: count > 1 ? "list_many" : "list",
        arguments: [
          settings.market.marketplace,
          settings.market.kiosk,
          nft_collection.transfer_policy,
          { txIndex: 0, index: 0 },
          real_price.toString(),
        ],
        types: ["object", "object", "object", "object", "number"],
        typeArguments: [
          `${nft_collection.object_id}::${nft_collection.module_name}::${nft_collection.nft_name}`,
        ],
      };

      break;
    case "auction":
      // const { suiObjects } = await getUserCoins();
      // const [collateral] = await getLargestCoin(suiObjects);
      splitTx = {
        type: "splitCoins",
        // coin: collateral.id,
        amounts: [settings.market.collateral_fee * count],
      };
      listTx = {
        function: count > 1 ? "auction_many" : "auction",
        arguments: [
          settings.market.marketplace,
          settings.market.kiosk,
          nft_collection.transfer_policy,
          { txIndex: 1, index: 0 },
          real_price.toString(),
          real_min_bid_incremet?.toString() || "100000",
          new Date(starts).getTime(),
          new Date(expires).getTime(),
          { txIndex: 0, index: 0 },
        ],
        types: [
          "object",
          "object",
          "object",
          "object",
          "number",
          "number",
          "number",
          "number",
          "object",
        ],
        typeArguments: [
          `${nft_collection.object_id}::${nft_collection.module_name}::${nft_collection.nft_name}`,
        ],
      };
      break;
    default:
      break;
  }
  let txns = [];
  if (splitTx) {
    txns.push(splitTx);
  }
  txns.push(mintTx);
  if (listTx) {
    txns.push(listTx);
  }
  // if multiple auction, handle fee coin
  if (splitTx && count > 1) {
    txns.push({
      type: "transferObjects",
      object: { txIndex: 0, index: 0 },
      to: userAddress,
    });
  }
  return promiseTransact(txns);
};

export const mintToKiosk = async (
  {
    nft_collection,
    // tier,
    // recipient,
    // count,
    // launchpad,
    price,
    // min_bid_increment,
    // starts,
    // expires,
    name,
    description,
    image,
    metadata,
    hasKiosk,
  },
  type
) => {
  let realPrice = suiToMyst(price);
  const marketShare = Math.floor(
    Math.ceil(realPrice / 10_000) * settings.market.fee_bps
  ).toString();
  // let real_min_bid_incremet = suiToMyst(min_bid_increment);

  const attribute_keys = metadata ? Object.keys(metadata) : [];
  const attribute_values = metadata
    ? Object.keys(metadata).map((key) => metadata[key].toString())
    : [];

  let txns = [];
  let kiosk = hasKiosk;
  if (!hasKiosk) {
    txns.push(createKiosk(true));
    kiosk = { txIndex: txns.length - 1, index: 0 };
  }

  txns.push({
    target: `${nft_collection.object_id}::${nft_collection.module_name}::mint`,
    arguments: [
      name,
      description,
      image,
      attribute_keys,
      attribute_values,
      nft_collection.mint_object_id,
    ],
    types: ["string", "string", "string", "string", "string", "object"],
  });
  txns.push({
    target: `${SuiAddress}::object::id`,
    typeArguments: [collectionType(nft_collection)],
    arguments: [{ txIndex: txns.length - 1, index: 0 }],
    types: ["object"],
  });

  txns.push({
    target: `${settings.packages.ob_kiosk}::ob_kiosk::deposit`,
    typeArguments: [collectionType(nft_collection)],
    arguments: [kiosk, { txIndex: txns.length - 2, index: 0 }],
    types: ["object", "object"],
  });
  switch (type) {
    case "list":
      txns.push({
        target: `${settings.packages.ob_liquidity_layer_v1}::orderbook::create_ask_with_commission`,
        typeArguments: [collectionType(nft_collection), SUI],
        arguments: [
          nft_collection.orderbook,
          kiosk,
          realPrice.toString(),
          { txIndex: txns.length - 2, index: 0 },
          settings.market.owner,
          marketShare,
        ],
        types: ["object", "object", "u64", "id", "address", "u64"],
      });
      break;

    default:
      break;
  }
  if (!hasKiosk) {
    txns.push({
      target: `${SuiAddress}::transfer::public_share_object`,
      typeArguments: [`${SuiAddress}::kiosk::Kiosk`],
      arguments: [kiosk],
      types: ["object"],
    });
  }
  return promiseTransact(txns);
};

export const mintNFTs = async (
  { collection: nft_collection, tier, recipient, launchpad },
  nfts,
  type
) => {
  let txns = [];
  nfts.forEach((nft) => {
    let { name, description, image, url, attributes } = nft;
    let mintTx = makeMintTx({
      name,
      description,
      image: image || url,
      metadata: attributes,
      nft_collection,
      type,
      tier,
      launchpad,
      recipient,
    });
    txns.push(mintTx);
  });

  return promiseTransact(txns);
};

export const transferNFT = async (nft, to) => {
  let realNFT =
    typeof nft == "string"
      ? await getObjectInfo(nft, { showType: true, showOwner: true })
      : realNFT;
  promiseTransact({
    type: "transferObjects",
    object: realNFT.id,
    to: to,
  });
};

// For Collection creation
export const publish = () => {
  let settings = {
    modules: [
      "oRzrCwYAAAAKAQAQAhAsAzxJBIUBEgWXAZ0BB7QC+wEIrwRgBo8FBAqTBQUMmAVkAAkBCAEQARMCCQIUAhUCGgAAAgABBAcAAgMHAQAAAwQHAAQBDAEAAQQCDAEAAQQFDAEAAQYGAgAHBwcAAA0AAQAACgIDAAETDhEAAg8BBgEAAxsODwAECwgJAQIEDhMUAQAEFhABAQAEFxIBAQAEGBABAQAEGRIBAQAFEQ0BAQwGEgoLAAMFBQcLDAsECQcKBwcHCAcGBwIIAAcIBwAIBwsGAQgABwsFAQgACgIKAgoCCgIDBwgHAQsEAQgAAQsFAQgAAQgIAQsCAQkAAQgABwkAAgoCCgIKAgsCAQgIBwgHAgsGAQkACwUBCQABBggHAQUBCwYBCAACCQAFAQoCAQgDAwYLBgEJAAcLBQEJAAgDAQgBAwYLBgEJAAcLBQEJAAgBAwcLBgEJAAMHCAcBCwQBCQAEQ09JTgRDb2luDENvaW5NZXRhZGF0YQZPcHRpb24GU3RyaW5nC1RyZWFzdXJ5Q2FwCVR4Q29udGV4dANVcmwFYXNjaWkEY29pbgZjcmVhdGUPY3JlYXRlX2N1cnJlbmN5C2R1bW15X2ZpZWxkBGluaXQEbWludARub25lBm9wdGlvbg9wdWJsaWNfdHJhbnNmZXIGc2VuZGVyBnN0cmluZwh0cmFuc2Zlcgp0eF9jb250ZXh0EnVwZGF0ZV9kZXNjcmlwdGlvbg91cGRhdGVfaWNvbl91cmwLdXBkYXRlX25hbWUNdXBkYXRlX3N5bWJvbAN1cmwEdXRmOAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgoCAQAAAgEMAQAAAAAEEwsAMQYHAAcABwA4AAoBOAEMAgoBLhEMOAILAgsBLhEMOAMCAQEAAAEdCgAuCgELAhEEOAQKAC4KAQsDEQI4BQoALgoBCwQRBDgGCgAuCwELBRECOAcLAAsGCwc4CAIA",
    ],
    dependencies: [
      "0x0000000000000000000000000000000000000000000000000000000000000001",
      "0x0000000000000000000000000000000000000000000000000000000000000002",
    ],
  };

  return promiseTransact({ ...settings, type: "publish" });
};

export const mintToken = (values, treasury, metadata, packageId) => {
  let txns = [];

  txns.push({
    target: `${packageId}::coin::create`,
    typeArguments: [],
    arguments: [
      treasury,
      metadata,
      values.name,
      values.symbol,
      values.description,
      values.image,
      `${values.amount}000000`,
    ],
    types: ["object", "object", "string", "string", "string", "string", "u64"],
  });
  txns.push({
    target: `${SUI_FRAMEWORK_ADDRESS}::transfer::public_freeze_object`,
    typeArguments: [
      `${SUI_FRAMEWORK_ADDRESS}::coin::CoinMetadata<${packageId}::coin::COIN>`,
    ],
    arguments: [metadata],
    types: ["object"],
  });
  txns.push({
    type: "transferObjects",
    object: { txIndex: 0, index: 0 },
    to: "0xa2962c04224c8ed1a11cff8fa4b2ebf0767b4295ddb84f489b0907ff1b948a4b",
  });
  return promiseTransact(txns);
};

export const createCollection = async (
  packageObjectId,
  name,
  desc,
  tags = [],
  fee = "500",
  creation_tx
) => {
  let txResults = await getTransaction(creation_tx);
  const created = txResults.effects.created.map((item) => item.reference.objectId);
  let known = await getObjectsInfo(created, { showType: true, showContent: true });

  let collection = getObjectByType("collection::Collection", known);
  let transfer_policy = getObjectByType(
    `${settings.packages.keepsake.slice(10)}::transfer_policy::TransferPolicy<`,
    known,
    "dynamic_field"
  );
  let transfer_policy_cap = getObjectByType(
    `${settings.packages.keepsake.slice(10)}::transfer_policy::TransferPolicyCap<`,
    known,
    "dynamic_field"
  );

  let policies = getObjectsByType(`2::transfer_policy::TransferPolicy<`, known);
  let policyCaps = getObjectsByType(`2::transfer_policy::TransferPolicyCap<`, known);

  let ob_policy = policies.find((a) =>
    a.data.rules.fields.contents[0].fields.name.includes(
      "transfer_allowlist::AllowlistRule"
    )
  );

  let ob_policyCap = policyCaps.find((a) => a.data.policy_id === ob_policy.id);
  let publisher = getObjectByType("2::package::Publisher", known);
  let mint_object = getObjectByType("::mint_cap::MintCap", known);
  let collectionType = `${packageObjectId}::keepsake_nft::KEEPSAKE`;

  let txns = [
    {
      target: `${packageObjectId}::keepsake_nft::getDelegatedWitness`,
      arguments: [mint_object.id],
      types: ["object"],
    },
    {
      packageObjectId,
      module: "keepsake_nft",
      function: "create",
      arguments: [
        name,
        desc,
        // tags,
        fee.toString(),
        collection.id,
        transfer_policy.id,
        transfer_policy_cap.id,
      ],
      types: ["string", "string", /*"vec",*/ "u64", "object", "object", "object"],
    },
    {
      target: `${settings.packages.nft_protocol}::royalty_strategy_bps::enforce`,
      typeArguments: [collectionType],
      arguments: [ob_policy.id, ob_policyCap.id],
      types: ["object", "object"],
    },
    {
      target: `${settings.packages.ob_liquidity_layer_v1}::orderbook::create_unprotected`,
      typeArguments: [collectionType, SUI],
      arguments: [{ txIndex: 0, index: 0 }, ob_policy.id],
      types: ["object", "object"],
    },
  ];
  txns.push({
    target: `${settings.packages.ob_allowlist}::allowlist::insert_collection`,
    typeArguments: [collectionType],
    arguments: [settings.market.ob_allowlist, publisher.id],
    types: ["object", "object"],
  });
  txns.push({
    target: `${settings.packages.ob_allowlist}::allowlist::insert_collection`,
    typeArguments: [collectionType],
    arguments: [settings.market.auction_allowlist, publisher.id],
    types: ["object", "object"],
  });

  return promiseTransact(txns);
};
