import {
  LOGIN,
  CONTENTS,
  BALANCES,
  CONNECTED,
  CONNECTING,
  LOADING,
  LOGOUT,
  KIOSKS,
  KIOSK_CONTENTS,
} from "redux/types";
import {
  getUserObjects,
  setProvider,
  loginSignature,
  updateGasObjects,
  getUserCoins,
  getObjectsOwnedByObject,
  getObjectsInfo,
  currentSettings,
  getObjectByType,
} from "web3/sui";
import {
  signIn,
  getCurrentUser,
  getBearerToken,
  setBearerToken,
  clearBearerToken,
  refreshInstance,
} from "utils/api";
import { getName } from "web3/suins";
import update from "immutability-helper";
import { sleep } from "utils/time";

const attemptSign = (address, signer) => {
  return loginSignature(signer)
    .then((res) => {
      return signIn(res);
    })
    .catch((e) => {
      return false;
    });
};

export const connect = (provider, signer) => async (dispatch) => {
  dispatch(setConnecting(true));
  const address = await signer.getAddress();
  if (address) {
    dispatch(setLoading(true));

    setProvider(provider, signer, address);
    // dispatch({ type: LOGIN });
    dispatch({
      type: CONNECTED,
      account: address,
    });

    let { total, suiObjects } = await getUserCoins();
    updateGasObjects(suiObjects);
    // TODO: When user only has 1 coin, set a flag to do a split at some point before the transaction
    dispatch(setBalances(suiObjects, total));
  }
  dispatch(setLoading(false));
};

export const disconnect = () => async (dispatch) => {
  clearBearerToken();
  dispatch({
    type: CONNECTED,
  });
  dispatch({
    type: LOGOUT,
  });
};

export const setConnecting = (connecting) => (dispatch) => {
  dispatch({
    type: CONNECTING,
    connecting,
  });
};

export const setLoading = (loading) => async (dispatch) => {
  dispatch({
    type: LOADING,
    loading,
  });
};

export const setBalances = (suiCoins, total) => async (dispatch) => {
  suiCoins.forEach((e, i) => {
    suiCoins[i].data.balance = parseInt(e.data.balance);
  });
  suiCoins.sort((a, b) => b.data.balance - a.data.balance);
  dispatch({
    type: BALANCES,
    total: parseInt(total),
    largest: parseInt(suiCoins[0]?.data.balance),
    suiObjects: suiCoins,
  });
};

export const getKiosks = (account_address) => async (dispatch) => {
  let settings = currentSettings();
  const kiosks = await getUserObjects({
    filter: {
      StructType: `${settings.object_ids.ob_kiosk}::ob_kiosk::OwnerToken`,
    },
    address: account_address,
  });
  let kioskData = await getObjectsInfo(kiosks.data.map((key) => key.data.kiosk));

  kioskData.reverse();
  dispatch({
    type: KIOSKS,
    data: kioskData,
  });
};

export const getObjectsByStructType = async (struct_type) => {
  const nfts = await getUserObjects({
    filter: {
      StructType: struct_type,
    },
  });
  let nftData = await getObjectsInfo(
    nfts.data.map((key) => key.data.id.id),
    { showDisplay: true, showType: true, showContent: true }
  );

  return nftData;
};

const fetchKioskItemsUntilRef = async (
  kiosk_id,
  prevData,
  cursor,
  exclusion,
  tries = 0
) => {
  let settings = currentSettings();
  let data = await getObjectsOwnedByObject(kiosk_id, cursor);

  let displayData = await getObjectsInfo(
    data.data.map((a) => a.objectId),
    { showDisplay: true, showType: true, showContent: true }
  );

  let refs = false;
  if (!exclusion) {
    refs = getObjectByType(
      `${settings.object_ids.ob_kiosk}::ob_kiosk::NftRef`,
      displayData
    );
  }

  displayData = displayData.filter((a) => a.display.data);
  let newData = prevData.concat(displayData);

  if (exclusion) {
    return {
      data: newData,
      nextCursor: data.nextCursor,
      hasNextPage: data.hasNextPage,
      exclusion,
    };
  } else if (refs) {
    let refsTable = await getObjectsOwnedByObject(refs.data.value.fields.id.id);
    let refsInfo = await getObjectsInfo(
      refsTable.data.map((a) => a.objectId),
      { showContent: true }
    );
    let skip = refsInfo
      .filter((a) => a.data.value.fields.is_exclusively_listed)
      .map((a) => a.data.name);
    return {
      data: newData,
      nextCursor: data.nextCursor,
      hasNextPage: data.hasNextPage,
      exclusion: skip,
    };
  } else if (tries < 5) {
    return fetchKioskItemsUntilRef(kiosk_id, newData, data.nextCursor, tries + 1);
  } else {
    return {
      data: newData,
      nextCursor: data.nextCursor,
      hasNextPage: data.hasNextPage,
    };
  }
};

export const getKioskItems = (kiosk_id, cursor, exclusion) => async (dispatch) => {
  let kiosk = await fetchKioskItemsUntilRef(kiosk_id, [], cursor, exclusion);

  kiosk.data = kiosk.data.filter((a) => a.display.data);
  kiosk.data.forEach((nft, index) => {
    if (Array.isArray(kiosk.exclusion) && kiosk.exclusion.includes(nft.id)) {
      kiosk.data[index].listed = true;
    }
  });

  dispatch({
    type: KIOSK_CONTENTS,
    kiosk_id: kiosk_id,
    kiosk,
  });
};

const getNextContents = async (nextCursor) => {
  let realNextCursor = nextCursor;
  let hasNextPage = false;
  let data = [];

  while (data.length < 12) {
    let nextPage = await getUserObjects({ cursor: realNextCursor });
    nextPage.data = nextPage.data
      .filter((a) => a.display.data)
      .filter((a) => !a.type.includes("ob_kiosk::OwnerToken"));
    data = data.concat(nextPage.data);
    hasNextPage = nextPage.hasNextPage;
    realNextCursor = nextPage.nextCursor;
    if (!hasNextPage) {
      break;
    }
  }
  return { nextCursor: realNextCursor, hasNextPage, data };
};

export const getContents =
  ({ data: currentContents, nextCursor: cursor }) =>
  async (dispatch) => {
    let { data, nextCursor, hasNextPage } = await getNextContents(cursor);

    currentContents = currentContents.concat(data.filter((a) => a));

    dispatch({
      type: CONTENTS,
      data: currentContents,
      nextCursor,
      hasNextPage,
    });
  };

const INIT_STATE = {
  connecting: false,
  connected: false,
  total: 0,
  largest: 0,
  loading: true,
  contents: {
    data: [],
    hasNextPage: false,
  },
  kiosks: [],
  kiosk_contents: {},
  suiObjects: [],
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONNECTING:
      return { ...state, connecting: action.connecting };
    case CONNECTED:
      return {
        ...INIT_STATE,
        loading: false,
        connected: !!action.account,
        account: action.account,
        name: action.name,
      };
    case LOADING:
      return { ...state, loading: action.loading };
    case BALANCES:
      return {
        ...state,
        total: action.total,
        suiObjects: action.suiObjects,
        largest: action.largest,
      };
    case CONTENTS:
      return {
        ...state,
        contents: {
          data: action.data,
          nextCursor: action.nextCursor,
          hasNextPage: action.hasNextPage,
        },
      };
    case KIOSKS:
      return {
        ...state,
        kiosks: action.data,
      };
    case KIOSK_CONTENTS:
      if (state.kiosk_contents[action.kiosk_id]) {
        let { data, hasNextPage, nextCursor } = action.kiosk;
        return update(state, {
          kiosk_contents: {
            [action.kiosk_id]: {
              data: { $push: data },
              hasNextPage: { $set: hasNextPage },
              nextCursor: { $set: nextCursor },
            },
          },
        });
      } else {
        return update(state, {
          kiosk_contents: {
            $merge: { [action.kiosk_id]: action.kiosk },
          },
        });
      }
    default:
      return state;
  }
};
export default reducer;
