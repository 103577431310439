import React from "react";
import { Field, FieldArray } from "redux-form";
import { renderFormV2, renderMetadataFields } from "utils/form";

const CreateItemDetails = () => {
  return (
    <div>
      <Field name="amount" type="number" component={renderFormV2} />
      <Field type="text" name="image" title="Image URL" component={renderFormV2} />
      <Field name="name" type="text" component={renderFormV2} />
      <Field name="symbol" type="text" component={renderFormV2} />
      <Field
        name="description"
        placeholder='e.g. "This is a very limited item"'
        type="textarea"
        component={renderFormV2}
      />
      {/* <FieldArray
        name="fields"
        title="Metadata Information"
        component={renderMetadataFields}
        rerenderOnEveryChange
      /> */}
    </div>
  );
};

export default CreateItemDetails;
