import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import CreateItemDetails from "./CreateItemDetails";
import { announceNFTs } from "utils/api";
import { getObjectByType, getTxObjects, mintToken, publish } from "web3/sui";
import { sleep } from "utils/time";
import { tryAgain } from "utils/performance";
import ToastPopup from "components/utils/ToastPopup";
import { IS_PROD } from "utils/environments";
import LoadingButton from "components/button/LoadingButton";
import axios from "axios";

const formName = "create-nft";

const validate = (values /*, dispatch */) => {
  const errors = {};

  if (values) {
    if (values.start_date >= values.end_date) {
      errors.start_date = "Start date cannot be after end date";
    }
    if (!values.image && IS_PROD) errors.image = "Required";
  }
  return errors;
};

const CreateItemForm = (props) => {
  // const navigate = useNavigate();
  const { handleSubmit, submitting, invalid } = props;

  const onSubmit = async (values) => {
    values.amount = values.amount || 1;

    let tx = await publish();
    // console.log(tx);

    await sleep();
    let [packageObjectId, txObjects] = await getTxObjects(tx);
    let treasury = getObjectByType(`TreasuryCap`, txObjects);
    let metadata = getObjectByType(`CoinMetadata`, txObjects);
    let tx2 = await mintToken(values, treasury.id, metadata.id, packageObjectId);
    await sleep();
    let [, txObjects2] = await getTxObjects(tx2);

    let coin = getObjectByType(`::coin::Coin<`, txObjects2);

    let timData = { mintAddress: coin.id, amount: values.amount, name: values.name };
    console.log(timData);
    let apiResponse = await axios.post(
      "https://dev.forwardgames.net:10443/api/v1/token/add",
      timData
    );
    if (apiResponse?.data) {
      ToastPopup("Nft(s) minted successfully", "success");
      // navigate(`/profile/owned-nfts`);
    }
  };

  return (
    <div className="list-item">
      <Header />
      <PageHeader />
      <div className="tf-list-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div className="flat-form flat-form-wide">
                <div className="flat-tabs tab-list-item">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <CreateItemImages
                      title="Uploads"
                      nftImage={nftImage}
                      setNFTImage={setNFTImage}
                    /> */}
                    <CreateItemDetails title="Details" />
                    <LoadingButton
                      type="submit"
                      loading={submitting}
                      disabled={submitting || invalid}
                    >
                      Mint
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default connect((state) => ({
  initialValues: state.initialValues[formName], // pull initial values from account reducer
}))(reduxForm({ form: formName, enableReinitialize: true, validate })(CreateItemForm));
