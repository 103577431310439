import { useTitle } from "components/utils/TitleProvider";
import { useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { getBasePath } from "utils/formats";
import PrivateRoute from "components/layouts/PrivateRoute";
import CreateItemForm from "./nfts/CreateItem/CreateItemForm";
//import WithdrawItemForm from "./nfts/CreateItem/WithdrawItemForm";

const routes = [
  {
    path: "/",
    component: <CreateItemForm />,
    title: "Create Token Wizard",
  },
  // {
  //   path: "/withdraw/:mint",
  //   component: <WithdrawItemForm />,
  //   title: "Create Token Wizard",
  // },
];

const Routing = () => {
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const basePath = getBasePath(location.pathname);
  const { setTitle } = useTitle();
  useEffect(() => {
    const currentRoute = routes.find((route) => route.path == location.pathname);
    if (currentRoute?.title) setTitle(currentRoute.title);
  }, [basePath]);

  return (
    <Routes>
      {routes.map((data, index) =>
        data.private ? (
          <Route
            path={data.path}
            key={"private" + index}
            element={
              <PrivateRoute
                user={user}
                element={data.component}
                key={"private" + index}
              />
            }
          ></Route>
        ) : (
          <Route
            index={data.index}
            onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={data.component}
            key={index}
          />
        )
      )}
    </Routes>
  );
};

export default Routing;
